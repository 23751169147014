import { Box, Button, makeStyles } from "@material-ui/core";
import React from "react";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(4),
    margin: `${theme.spacing(2)}px 0`,
  },
  agreeButton: {
    background: "green",
    color: "white",
    "&:hover": {
      background: "green",
    },
  },
}));

function StatusSelect({ setIsDialogOpen, isReasonDialogOpen }) {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Button
        variant="contained"
        className={classes.agreeButton}
        onClick={isReasonDialogOpen}>
        Решено
      </Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={setIsDialogOpen}>
        Не решено
      </Button>
    </Box>
  );
}

export default StatusSelect;
