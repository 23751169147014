export function setAvaibleLists(avaibleList) {
  return {
    type: "SET_AVAIBLELISTS",
    avaibleList,
  };
}

export function setCurrentList(currentList) {
  return {
    type: "SET_CURRENTLIST",
    currentList,
  };
}

export function setSuccessMessage(successMessage) {
  return {
    type: "SET_SUCCESS_MESSAGE",
    successMessage,
  };
}

export function setErrorMessage(errorMessage) {
  return {
    type: "SET_ERROR_MESSAGE",
    errorMessage,
  };
}

export function getListsConfig() {
  return async (dispatch, getState) => {
    const state = getState(),
      client = state.page.elkUser.restClient;
    const projectId = state.events.currentEvent.obj_id;
    let avaibleList = await client.get(`/service_desc/org/list-menu/?project_id=${projectId}`);
    dispatch(setAvaibleLists(avaibleList));
  };
}

export function getCurrentList(url) {
  return async (dispatch, getState) => {
    const state = getState(),
      client = state.page.elkUser.restClient;
    let currentList = await client.get(`${url}`);
    if (!!currentList.context_menu?.actions?.length) {
      const key = currentList["context_menu"]?.actions[0]?.key;
      const add = currentList["context_menu"]?.actions[0];
      const deleteAction = currentList["context_menu"]?.actions[1];
      const addAction = {
        code: "user_binding",
        is_mass: false,
        onClick: async (rowData) => {
          const orgObjId = rowData[key];
          try {
            if (orgObjId) {
              await client.post(`${add.action_url}`, { [key]: orgObjId });
              dispatch(setSuccessMessage("Привязка успешно произведена"));
            }
          } catch (e) {
            console.log("Ошибка привязки", e);
            const errorMessage = e || e.toString();

            if (errorMessage.includes("company already assigned")) {
                dispatch(setErrorMessage("Данная компания уже имеет привязку"));
            }
            if(errorMessage.includes("user already assigned")){
                dispatch(setErrorMessage("Данный исполнитель уже имеет привязку"));
            }
            else  dispatch(setErrorMessage("Ошибка привязки, повторите позже или попробуйте еще раз"));
          }
        },
      };
      const newDeleteAction = {
        code: "user_delete",
        is_mass: false,
        onClick: async (rowData) => {
          const orgObjId = rowData[key];
          try {
            if (orgObjId) {
              await client.post(`${deleteAction.action_url}`, { [key]: orgObjId });
              dispatch(setSuccessMessage("Успешно убрано"));
            }
          } catch (e) {
            console.log("Ошибка привязки", e);
            const errorMessage = e || e.toString();

            if (errorMessage.includes("performer")) {
              dispatch(setErrorMessage("Данный исполнитель уже не привязан к вам"));
            } else if (errorMessage.includes("you are not owner")) {
              dispatch(setErrorMessage("Данная компания уже не привязана к вам"));
            } else {
              dispatch(setErrorMessage("Ошибка отвязки, повторите позже или попробуйте еще раз"));
            }
          }
        },
      };
      currentList["context_menu"].actions[0] = { ...add, ...addAction };
      currentList["context_menu"].actions[1] = { ...deleteAction, ...newDeleteAction };
    }
    dispatch(setCurrentList(currentList));
  };
}
