import React from "react";
import { connect } from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import Crud from "@ksbteam/core/components/Crud";
import Grid from "@material-ui/core/Grid";
import Field from "@ksbteam/core/components/Form/Field";
import { eventsInit } from "../../../actions/events";
import { getRows } from "../../helpers/helpers";

class Events extends React.Component {
  componentDidMount() {
    if (this.props.isAdmin) {
      this.props.init();
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.isAdmin && prevProps.isAdmin !== this.props.isAdmin) {
      this.props.init();
    }
  }

  constructor(props) {
    super(props);
    this.restClient = props.restClient;
  }

  fieldComponent(field, defaultRowProps) {
    let fieldCopy = Object.assign({}, field, {
      restClient: this.restClient,
    });

    delete fieldCopy.label;

    if (field?.attrs?.type === "image") {
      fieldCopy.downloadUrl = "service_desc/file-storage/img?project=1";
      fieldCopy.uploadUrl = "service_desc/file-storage/set-img?project=1";
    }

    return (
      <div style={{ display: "flex" }}>
        <Grid
          item
          md={3}
          sm={3}
          xs={12}
          {...defaultRowProps}>
          {field?.attrs?.type !== "hidden" && (
            <label htmlFor={field?.attrs?.name}>
              {field.label}
              {!!field.required && <sup style={{ color: "#ff0000" }}>*</sup>}
            </label>
          )}
        </Grid>
        <Grid
          item
          md={9}
          sm={9}
          xs={12}
          {...defaultRowProps}>
          <Field
            {...fieldCopy}
            useDisabledField={true}
          />
        </Grid>
      </div>
    );
  }

  render() {
    let { inited, eventsConfig, restClient } = this.props;
    return (
      <React.Fragment>
        {!inited && <LinearProgress variant="query" />}
        {inited && (
          <React.Fragment>
            {!eventsConfig ? (
              <div style={{ fontSize: "larger" }}>Недостаточно прав</div>
            ) : (
              <Crud
                restClient={restClient}
                config={eventsConfig}
                dataGridProps={{ filters: true, getRows: getRows }}
                simpleFormProps={{
                  fieldComponent: this.fieldComponent,
                }}
                dialogProps={{
                  maxWidth: "md",
                }}
              />
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    inited: state.events.inited,
    eventsConfig: state.events.eventsConfig,
    restClient: state.page.elkUser.restClient,
    isAdmin: state.page.isAdmin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    init: () => dispatch(eventsInit()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Events);
