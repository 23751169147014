import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import Datetime from "@ksbteam/core/components/Form/Field/Datetime";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import FilterListIcon from "@material-ui/icons/FilterList";
import {
  ticketsUpdateFilters,
  ticketsClearFilters,
  ticketsApplyFilters,
  ticketsOpenFiltersDialog,
  ticketsCloseFiltersDialog,
} from "../../actions/tickets";
import {
  reportsUpdateFilters,
  reportsClearFilters,
  reportsApplyFilters,
  reportsOpenFiltersDialog,
  reportsCloseFiltersDialog,
} from "../../actions/reports";
import DefaultDialog from "@ksbteam/core/components/Dialog/DefaultDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    zIndex: 50,
    [`@media (max-width: 1150px)`]: {
      display: "none",
    },
    "&.mobile": {
      display: "block",
      position: "relative",
      right: 0,
    },
  },
  dialogButtonWrapper: {
    display: "flex",
  },
  label: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    ".mobile &": {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  searchButton: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    ".mobile &": {
      marginLeft: 0,
    },
  },
  dateWrapper: {
    ".mobile &": {
      marginTop: theme.spacing(),
      marginBottom: theme.spacing(),
    },
  },
  filtersButtonMobile: {
    textTransform: "none",
  },
   reportsButtonMobile: {
    marginBottom: theme.spacing(3),
  },
}));

const FiltersForm = ({ classes, filters, find, clearFilters, setDate, isMobileDevice }) => (
  <div className={classes.root + (isMobileDevice ? " mobile" : "")}>
    <div className={classes.label}>Показать инциденты с</div>
    <div className={classes.dateWrapper}>
      <Datetime
        onChange={setDate}
        name={"min"}
        value={filters?.date?.min}
      />
    </div>
    <div className={classes.label}>по</div>
    <div className={classes.dateWrapper}>
      <Datetime
        onChange={setDate}
        name={"max"}
        value={filters?.date?.max}
        needValidation={false}
      />
    </div>
    <div>
      <Button
        className={classes.searchButton}
        variant={"outlined"}
        onClick={find}>
        <SearchIcon /> Искать
      </Button>
      <IconButton
        onClick={clearFilters}
        aria-label={"Очистить фильтры"}>
        <CancelIcon />
      </IconButton>
    </div>
  </div>
);

const Filters = ({ type }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const filters = useSelector((state) => (type === "tickets" ? state.tickets.filters : state.reports.filters));
  const isMobileDevice = useSelector((state) => state.page.isMobileDevice);
  const filtersDialog = useSelector((state) => (type === "tickets" ? state.tickets.filtersDialog : state.reports.filtersDialog));

  const setDate = (name, date, dateFormatted) => {
    let newFilters = { ...filters };
    if (!newFilters.date) {
      newFilters.date = {};
    }
    newFilters.date[name] = dateFormatted;
    if (type === "tickets") {
      dispatch(ticketsUpdateFilters(newFilters));
    } else {
      dispatch(reportsUpdateFilters(newFilters));
    }
  };

  const find = () => {
    if (type === "tickets") {
      dispatch(ticketsApplyFilters());
    } else {
      dispatch(reportsApplyFilters());
    }
    if (isMobileDevice) {
      if (type === "tickets") {
        dispatch(ticketsCloseFiltersDialog());
      } else {
        dispatch(reportsCloseFiltersDialog());
      }
    }
  };

  const clearFilters = () => {
    if (type === "tickets") {
      dispatch(ticketsClearFilters());
    } else {
      dispatch(reportsClearFilters());
    }
  };

  const openFiltersDialog = () => {
    if (type === "tickets") {
      dispatch(ticketsOpenFiltersDialog());
    } else {
      dispatch(reportsOpenFiltersDialog());
    }
  };

  const closeFiltersDialog = () => {
    if (type === "tickets") {
      dispatch(ticketsCloseFiltersDialog());
    } else {
      dispatch(reportsCloseFiltersDialog());
    }
  };

  const filtersForm = (
    <FiltersForm
      classes={classes}
      filters={filters}
      find={find}
      clearFilters={clearFilters}
      setDate={setDate}
      isMobileDevice={isMobileDevice}
    />
  );

  return (
    <React.Fragment>
      {isMobileDevice && (
        <React.Fragment>
          <div className={classes.dialogButtonWrapper}>
            <Button
              className={`${classes.filtersButtonMobile} ${type === "reports" ? classes. reportsButtonMobile : ""}`}
              onClick={openFiltersDialog}
              variant={"contained"}
              color={"secondary"}>
              <FilterListIcon /> Фильтры
            </Button>
          </div>
          <DefaultDialog
            title={"Фильтры"}
            onClose={closeFiltersDialog}
            maxWidth={"sm"}
            fullScreen={true}
            {...filtersDialog}>
            {filtersForm}
          </DefaultDialog>
        </React.Fragment>
      )}
      {!isMobileDevice && filtersForm}
    </React.Fragment>
  );
};

export default Filters;
