import React from "react";
import { withStyles } from "@material-ui/core/styles";
import SaveIcon from "@material-ui/icons/Save";
import { connect, useDispatch } from "react-redux";
import Decide from "./Decide";
import StatusSelect from "./StatusSelect";
import { ticketsCloseTicket } from "../../actions/tickets";
import CancelDialog from "./CancelDialog";
import { ticketAddComment, ticketSave } from "../../actions/ticket";
import ReasonDialog from "./ReasonDialog";
import { Button } from "@material-ui/core";

const styles = (theme) => ({
  ticketButtons: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    alignItems: "flex-start",
  },
  button: {
    display: "flex",
    gap: theme.spacing(),
    alignItems: "center",
    justifyContent: "center",
  },
});

const TicketButtons = ({
  hasRoles,
  IsDirector,
  IsDircontractor,
  IsContractor,
  IsSubcontractor,
  isExecutor,
  classes,
  isAdmin,
  isResponsinble,
  status,
  client,
  projectId,
  ticketId,
  closeTicket,
  save,
  editDisabled,
}) => {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [isDoneDialogOpen, setIsDoneDialogOpen] = React.useState(false);
  const dispatch = useDispatch();

  const getReason = () => {
    if (isExecutor) {
      if (status.value === "1569998") {
        return "того, почему инцидент не решен";
      } else return "отказа";
    }
    if (IsDircontractor || IsContractor || IsSubcontractor) {
      return "того, почему инцидент необходимо вернуть на доработку";
    }
  };

  const handleChangeStatus = async (reason, isComplete) => {
    let sendStatus = {};
    if (isExecutor) {
      if (status.value === "1569911" || status.value === "1570000") {
        if (!isComplete) {
          sendStatus.status_code = "not_completed";
        } else sendStatus.status_code = "completed";
      } else {
        sendStatus.status_code = "rejected";
      }
    }
    if (IsSubcontractor || IsContractor || IsDircontractor) {
      sendStatus.status_code = "rework";
    }
    try {
      let statusResponse = await client.post(`/service_desc/ticket/define-status/?ticket_id=${ticketId}&project_id=${projectId}`, sendStatus);
      if (reason) {
        dispatch(ticketAddComment(reason));
      }
      closeTicket();
    } catch (e) {
      console.log("Ошибка отказа", e);
    }
  };
  const IsDirectorAndStatus = IsDirector && status.value === "1569912";
  const isDircontractorAndStatus = IsDircontractor && status.value === "1569912";
  const isRelevantContractor = IsContractor || IsSubcontractor;
  const isRelevantStatus = status.value === "1569912";
  const isExecutorAndStatus = isExecutor && (status.value === "1569911" || status.value === "1570000");
  const canPerformAction =
    hasRoles &&
    ((isRelevantContractor && isRelevantStatus) || isDircontractorAndStatus || IsDirectorAndStatus) &&
    isResponsinble &&
    !isExecutor &&
    status?.value === "1569913";

  return (
    <div className={classes.ticketButtons}>
      {isAdmin && (
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          onClick={save}
          disabled={editDisabled}>
          <SaveIcon />
          Сохранить
        </Button>
      )}
      {canPerformAction && !isAdmin && (
        <Decide
          IsDircontractor={IsDircontractor}
          IsContractor={IsContractor}
          IsSubcontractor={IsSubcontractor}
          isExecutor={isExecutor}
          isResponsinble={isResponsinble}
          client={client}
          setIsDialogOpen={() => setIsDialogOpen(true)}
          projectId={projectId}
          ticketId={ticketId}
          closeTicket={closeTicket}
          status={status}
        />
      )}
      {isResponsinble && isExecutorAndStatus && !isAdmin && (
        <StatusSelect
          setIsDialogOpen={() => setIsDialogOpen(true)}
          isReasonDialogOpen={() => setIsDoneDialogOpen(true)}
        />
      )}
      <CancelDialog
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        reasonText={getReason()}
        handleSubmit={handleChangeStatus}
      />
      <ReasonDialog
        isDialogOpen={isDoneDialogOpen}
        setIsDialogOpen={setIsDoneDialogOpen}
        handleSubmit={handleChangeStatus}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isAdmin: state.page.isAdmin,
    isResponsinble: state.ticket.isResponsinble,
    status: state.ticket.status,
    client: state.page.elkUser.restClient,
    projectId: state.events.currentEvent.obj_id,
    ticketId: state.tickets.ticketId,
    editDisabled: state.ticket.editDisabled,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeTicket: () => dispatch(ticketsCloseTicket()),
    save: () => dispatch(ticketSave()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TicketButtons));
