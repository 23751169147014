import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router";
import Login from "../components/Login";

const LoginPage = () => {
  const elkUser = useSelector((state) => state.page.elkUser);

  if (elkUser.loggedIn() && !elkUser.passedTwoStepAuth()) {
    return (
      <Redirect
        push
        to="/two-step-auth"
      />
    );
  }

  if (elkUser.loggedIn() && elkUser.passedTwoStepAuth()) {
    localStorage.setItem('isFirstLogin', true)
    return (
      <Redirect
        push
        to="/main"
      />
    );
  }

  return <Login />;
};

export default LoginPage;
