import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { pageToggleDrawerLeft } from "../../actions/page";
import { mainMenu } from "../../api/MainMenu";

const drawerWidth = 280;

const styles = (theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    border: "none",
    backgroundColor: theme.palette.background.default,
  },
  toolbar: theme.mixins.toolbar,
  navLink: {
    display: "block",
    backgroundColor: "transparent",
    color: theme.palette.text.primary,
    textDecoration: "none",
    // borderRadius: '0 50px 50px 0',
    "&.active": {
      background: "#e7e7e7",
    },
    "&.active *": {
      // color: '#1967d2',
    },
  },
  listItem: {
    // borderRadius: '0 50px 50px 0',
  },
});

class DrawerLeft extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuItems: mainMenu.getHeaderItems(this.props.reportsCount, this.props.roles, this.props.isAdmin),
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.reportsCount !== this.props.reportsCount || prevProps.roles !== this.props.roles) {
      this.setState({ menuItems: mainMenu.getHeaderItems(this.props.reportsCount, this.props.roles, this.props.isAdmin) });
    }
  }
  render() {
    const { classes, reportsCount } = this.props;
    const menuItems = mainMenu.getHeaderItems(this.props.reportsCount, this.props.roles, this.props.isAdmin);
    return (
      <Drawer
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
        {...this.props.drawerLeft}
        onClose={this.props.toggleDrawerLeft}>
        <List>
          {menuItems.map((menuItem) => (
            <NavLink
              key={menuItem.url}
              to={menuItem.url}
              className={classes.navLink}
              onClick={this.props.toggleDrawerLeft}>
              <ListItem
                button
                className={classes.listItem}>
                <ListItemText primary={menuItem.title} />
              </ListItem>
            </NavLink>
          ))}
        </List>
        {/*<Divider/>*/}
      </Drawer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    drawerLeft: state.page.drawerLeft,
    reportsCount: state.reports.reports.length,
    roles: state.profile.profileData?.roles || [],
    isAdmin: state.page.isAdmin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleDrawerLeft: () => dispatch(pageToggleDrawerLeft()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DrawerLeft));
