export function rolesSetInited(inited) {
    return {
        type: 'ROLES_SET_INITED',
        inited
    };
}

export function rolesSetReports(rolesConfig) {
    return {
        type: 'ROLES_SET_ROLES',
        rolesConfig
    };
}

export function rolesInit() {
    return async (dispatch, getState) => {
        const state = getState();
        dispatch(rolesSetInited(false));
        let rolesConfig = await state.page.elkUser.getConfig('roles');
        dispatch(rolesSetReports(rolesConfig));
        dispatch(rolesSetInited(true));
    };
}

