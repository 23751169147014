function customSort(data, key, sortOrder) {
  if ((typeof data[0][key] === "string" || data[0][key] === null) && sortOrder !== "NONE") {
    const sortFactor = sortOrder.toUpperCase() === "ASC" ? 1 : -1;

    return [...data].sort((a, b) => {
      let valueA = a[key];
      let valueB = b[key];

      if (valueA === "Итого") return 1;
      if (valueB === "Итого") return -1;

      if (valueA === null && valueB === null) return 0;
      if (valueA === null) return sortFactor;
      if (valueB === null) return -sortFactor;

      return sortFactor * valueA.localeCompare(valueB);
    });
  } else return data;
}

export function getRows(rows, state) {
  if (!!state && !!state?.sortColumn && !!state?.sortDirection && rows.length > 1) {
    return customSort(rows, state.sortColumn, state.sortDirection);
  } else return rows;
}
export function getName(text) {
  switch (text) {
    case "dircontractor":
      return "Подрядчик по направлению";
    case "contractor":
      return "Подрядчик";
    case "subcontractor":
      return "Субподрядчик";
    case "performer":
      return "Исполнитель";
    default:
      return text;
  }
}
