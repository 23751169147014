import React from "react";
import { companiesInit } from "../../../actions/companies";
import { getRows } from "../../helpers/helpers";
import { connect } from "react-redux";
import Crud from "@ksbteam/core/components/Crud";

function Companies({ companiesConfig, init, inited, restClient, isAdmin }) {
  React.useEffect(() => {
    if (isAdmin) {
      init();
    }
  }, [isAdmin]);

  return (
    inited &&
    companiesConfig &&
    Object.keys(companiesConfig)?.length !== 0 && (
      <Crud
        restClient={restClient}
        config={companiesConfig}
        dataGridProps={{ filters: true, getRows: getRows }}
      />
    )
  );
}

const mapStateToProps = (state) => {
  return {
    inited: state.companies.inited,
    companiesConfig: state.companies.companiesConfig,
    restClient: state.page.elkUser.restClient,
    isAdmin: state.page.isAdmin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    init: () => dispatch(companiesInit()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Companies);
