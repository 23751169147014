export function directoriesSetInited(inited) {
    return {
        type: 'DIRECTORIES_SET_INITED',
        inited
    };
}

export function directoriesSetDirectories(directoriesConfig) {
    return {
        type: 'DIRECTORIES_SET_DIRECTORIES',
        directoriesConfig
    };
}

export function getDirectoriesConfig(obj_type_code) {
    return async (dispatch, getState) => {
        const state = getState();
        let directoriesConfig = await state.page.elkUser.getConfig(obj_type_code);
        dispatch(directoriesSetDirectories(directoriesConfig));
    };
}

export function directoriesInit() {
    return async (dispatch, getState) => {
        const state = getState(), client = state.page.elkUser.restClient;
        dispatch(directoriesSetInited(false));
        let directoriesConfig = await client.get('service_desc/report/list');
        dispatch(directoriesSetDirectories(directoriesConfig));
        dispatch(directoriesSetInited(true));
    };
}

