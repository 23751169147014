import { ticketsInitFormWidgets } from "./tickets";
import moment from "moment";

export function ticketSetFormConfig(formConfig) {
  return {
    type: "TICKET_SET_FORM_CONFIG",
    formConfig,
  };
}

export function ticketSetInited(inited) {
  return {
    type: "TICKET_SET_INITED",
    inited,
  };
}

export function ticketResetState() {
  return {
    type: "TICKET_RESET_STATE",
  };
}

export function ticketSetFormTempValues(values) {
  return {
    type: "TICKET_SET_FORM_TEMP_VALUES",
    formTempValues: values,
  };
}

export function ticketSetEditMode(bool) {
  return {
    type: "TICKET_SET_EDIT_MODE",
    editMode: bool,
  };
}

export function ticketSetEditDisabled(bool) {
  return {
    type: "TICKET_SET_EDIT_DISABLED",
    editDisabled: bool,
  };
}

export function ticketSave() {
  return async (dispatch, getState) => {
    if (window.confirm("Сохранить изменения?")) {
      const state = getState(),
        form = state.ticket.formRef,
        values = form.getPreparedValues(),
        dataGrid = state.tickets.crudRef.dataGrid;
      let formConfig = Object.assign({}, state.ticket.formConfig);
      const client = state.page.elkUser.restClient;
      try {
        await client.post(formConfig.action, values);
        dispatch(ticketSetFormTempValues(form.getPreparedValues()));
        dataGrid.updateRows();
        alert("Данные успешно сохранены");
      } catch (e) {
        alert("Возникла ошибка во время сохранения", e);
      }
    }
  };
}

function ticketSaveLevel(data) {
  return async (dispatch, getState) => {
    const state = getState(),
      dataGrid = state.tickets.crudRef.dataGrid,
      ticketId = state.tickets.ticketId,
      projectId = state.events.currentEvent.obj_id;
      const client = state.page.elkUser.restClient;
      try {
        await client.post(`/service_desc/ticket/set-incident-level/?ticket_id=${ticketId}&project_id=${projectId}`, data);
        dataGrid.updateRows();
        alert("Данные успешно сохранены");
      } catch (e) {
      alert("Возникла ошибка во время сохранения", e);
    }
  };
}

export function ticketSetComments(comments) {
  return {
    type: "TICKET_SET_COMMENTS",
    comments,
  };
}

export function ticketSetHistory(history) {
  return {
    type: "TICKET_SET_HISTORY",
    history,
  };
}

export function ticketSetFormRef(formRef) {
  return {
    type: "TICKET_SET_FORM_REF",
    formRef,
  };
}

export function ticketSetStatus(status) {
  return {
    type: "TICKET_SET_STATUS",
    status,
  };
}

export function ticketSetCoords(coords) {
  return {
    type: "TICKET_SET_COORDS",
    coords,
  };
}

export function ticketSetResponsible(responsible) {
  return {
    type: "TICKET_SET_RESPONSIBLE",
    responsible,
  };
}

export function ticketUpdateStatus(status) {
  return async (dispatch, getState) => {
    if (window.confirm(`Изменить статус инцидента на "${status.title}"?`)) {
      try {
        const state = getState(),
          dataGrid = state.tickets.crudRef.dataGrid,
          ticketId = state.tickets.ticketId;
        const client = state.page.elkUser.restClient;
        client.get(`service_desc/ticket/set-status?ticket_id=${ticketId}&status=${status.value}`);
        dispatch(ticketSetStatus(status));
        dataGrid.updateRows();
        alert(`Статус инцидента успешно изменен на "${status.title}"`);
      } catch (e) {
        console.error(e);
        alert("Возникла ошибка при установке статуса");
      }
    }
  };
}

export function ticketInit() {
  return async (dispatch, getState) => {
    const state = getState(),
      client = state.page.elkUser.restClient,
      projectId = state.events.currentEvent.obj_id;
    let { formConfigUrl } = state.ticket,
      { ticketId } = state.tickets;
    dispatch(ticketResetState());
    let response = await Promise.all([
      client.get(`${formConfigUrl}&service_desc-obj_id=${ticketId}&project_id=${projectId}`),
      client.get(`service_desc/ticket/get-status?ticket_id=${ticketId}`),
      getComments(ticketId, client),
      client.get(`service_desc/history/list?ticket_id=${ticketId}`),
    ]);
    let formConfig = response[0];
    formConfig = prepareFormConfig(formConfig, dispatch);
    let status = response[1]?.status;
    dispatch(ticketSetStatus(status));
    dispatch(ticketSetResponsible(response[1]?.is_responsible) || false);
    dispatch(ticketSetFormConfig(formConfig));
    let comments = response[2];
    dispatch(ticketSetComments(comments));
    let history = response[3];
    dispatch(ticketSetHistory(history));
    dispatch(ticketSetInited(true));
  };
}

function prepareFormConfig(formConfig, dispatch) {
  if (!!formConfig.values && !!formConfig.values["service_desc-latitude"] && !!formConfig.values["service_desc-longitude"]) {
    dispatch(
      ticketSetCoords({
        latitude: formConfig.values["service_desc-latitude"],
        longitude: formConfig.values["service_desc-longitude"],
      })
    );
  }
  formConfig = ticketsInitFormWidgets(formConfig);
  formConfig.fields.map((field) => {
    if (field.attrs.name === "service_desc-incident_level") {
      field.onChange = (fieldName, value) => {
        dispatch(ticketSaveLevel({ [fieldName]: value ? value : 0 }));
        field.disabled = true
      };
    }
    return field;
  });
  let editDisabled = formConfig.fields.every((field) => !!field.attrs.disabled);
  dispatch(ticketSetEditDisabled(editDisabled));
  let photoField = formConfig.fields.find((field) => field.attrs.name === "service_desc-photo");
  photoField.crop = false;
  return formConfig;
}

export function ticketAddComment(comment) {
  return async (dispatch, getState) => {
    const state = getState(),
      { ticketId } = state.tickets,
      client = state.page.elkUser.restClient;
    try {
      await client.post("service_desc/comment/save", {
        ticket_id: ticketId,
        comment,
      });
      let comments = await getComments(ticketId, client);
      dispatch(ticketSetComments(comments));
    } catch (e) {
      alert("Возникла ошибка при добавлении комментария: " + e.toString());
    }
  };
}

async function getComments(ticketId, client) {
  let comments = await client.get(`service_desc/comment/list?ticket_id=${ticketId}`);
  comments.forEach((comment) => (comment.created = !!comment.created ? moment(comment.created).format("DD.MM.YYYY HH:mm:ss") : null));
  return comments;
}
