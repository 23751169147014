import React, { useEffect } from "react";
import { BrowserRouter, useHistory } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider, createTheme, makeStyles } from "@material-ui/core/styles";
import "../elk.css";
import { mainMenu } from "../api/MainMenu";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import ProtectedRoutes from "./ProtectedRoutes";
import { getEvents } from "../actions/events";
import { getReports } from "../actions/reports";
import { setAdminStatus } from "../actions/page";
import { profileInit } from "../actions/profile";

const useStyles = makeStyles(() => ({
  progressWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 300,
  },
}));

const theme = createTheme({
  typography: {
    h1: {
      fontSize: 32, //37 - в макете
      lineHeight: 1.15,
    },
    h2: {
      fontSize: 25,
    },
    body1: {
      fontSize: 14,
    },
    body2: {
      fontSize: 16, //18 - в макете
    },
  },
  palette: {
    secondary: {
      light: "#ab0d28",
      main: "#a50e2d",
    },
    text: { primary: "#5a5a5a" },
    background: {
      default: "#ffffff",
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        label: {
          fontSize: "18px !important",
        },
      },
    },
    MuiInput: {
      formControl: {
        "label + &": {
          marginTop: 18,
        },
      },
    },
  },
});

const Page = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const elkUser = useSelector((state) => state.page.elkUser);
  const isMobileDevice = useSelector((state) => state.page.isMobileDevice);
  const currentEvent = useSelector((state) => state.events.currentEvent);
  const projectId = useSelector((state) => state.events.currentEvent.obj_id);
  const isLoaded = useSelector((state) => state.page.isLoaded);
  const isRolesLoaded = useSelector((state) => state.profile.inited);
  const roles = useSelector((state) => state.profile.profileData?.roles);

  useEffect(() => {
    if (elkUser._authToken) {
      dispatch(getEvents());
      (async () => {
        dispatch(setAdminStatus(await elkUser.getAdminStatus()));
      })();
    }
  }, [dispatch, elkUser]);

  useEffect(() => {
    if (currentEvent && Object.keys(currentEvent).length !== 0 && currentEvent.obj_id) {
      dispatch(getReports(currentEvent.obj_id));
    }
  }, [dispatch, currentEvent]);

  useEffect(() => {
    dispatch(profileInit());
  }, [dispatch, projectId]);

  const routerItems = mainMenu.getRouterItems();

  if (
    !elkUser ||
    isMobileDevice === null ||
    (!isLoaded && !history?.location?.path?.includes("/login") && !(isRolesLoaded && roles.some((role) => role === "service_desc_director")))
  ) {
    return (
      <div className={classes.progressWrapper}>
        <CircularProgress size={40} />
      </div>
    );
  }

  return (
    <BrowserRouter>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {routerItems.map((item, index) => (
          <ProtectedRoutes
            key={item.url + index}
            path={item.url}
            component={item.component}
            location={history?.location}
          />
        ))}
      </MuiThemeProvider>
    </BrowserRouter>
  );
};

export default Page;
