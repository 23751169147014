import ElkUser from "../api/ElkUser";
import { isMobileDevice } from "@ksbteam/core/api/MobileDevice/index";

const elkUser = new ElkUser();

const initialState = {
  drawerLeft: { open: false },
  isMobileDevice: isMobileDevice(),
  elkUser: elkUser,
  isAdmin: false,
  firebaseInited: false,
  isLoaded: false,
};

export function page(state = Object.assign({}, initialState), action) {
  switch (action.type) {
    case "PAGE_TOGGLE_DRAWER_LEFT":
      return Object.assign({}, state, {
        drawerLeft: {
          open: !state.drawerLeft.open,
        },
      });
    case "PAGE_FIREBASE_SET_INITED":
      return Object.assign({}, state, {
        firebaseInited: action.inited,
      });
    case "PAGE_SET_ADMIN_STATUS":
      return Object.assign({}, state, {
        isAdmin: action.isAdmin,
      });
    case "PAGE_SET_LOADED":
      return Object.assign({}, state, {
        isLoaded: action.isLoaded,
      });
    default:
      return state;
  }
}
