import React from "react";
import { useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { reportsExport } from "../../actions/reports";

const useStyles = makeStyles((theme) => ({
  button: {
    minHeight: "auto",
    width: "15%",
    height: 35,
    border: "1px solid " + theme.palette.secondary.main,
    borderRadius: "5px",
    textTransform: "none",
    padding: 0,
    color: theme.palette.secondary.main,
    cursor: "pointer",
    ".mobile &": {
      width: "50%",
      maxWidth: "50%",
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette.secondary.main,
      color: "#ffffff",
    },
  },
}));

function ExportButton({ exportPath }) {
  const dispatch = useDispatch()
  const classes = useStyles();
  const handleClick = () => {
      dispatch(reportsExport(exportPath))
  }

  return <Button className={classes.button} onClick={handleClick}>Экспорт</Button>;
}

export default ExportButton;
