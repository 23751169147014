export function reportsSetInited(inited) {
  return {
    type: "REPORTS_SET_INITED",
    inited,
  };
}

export function reportsSetCurrentReport(currentReport) {
  return {
    type: "REPORTS_SET_CURRENT_REPORT",
    currentReport,
  };
}

export function reportsUpdateCurrentReport(e) {
  return async (dispatch) => {
    dispatch(reportsSetCurrentReport(e.target.value));
  };
}

export function reportsSetReports(reports) {
  return {
    type: "REPORTS_SET_REPORTS",
    reports,
  };
}

export function reportsSetReportList(reportList) {
  return {
    type: "REPORTS_SET_REPORTLIST",
    reportList,
  };
}

export function reportsSetFilters(filters) {
  return {
    type: "REPORTS_SET_FILTERS",
    filters,
  };
}

export function reportsUpdateFilters(filters) {
  return async (dispatch) => {
    dispatch(reportsSetFilters(filters));
  };
}

export function reportsApplyFilters() {
  return async (dispatch, getState) => {
    const state = getState(),
      dataGrid = state.reports.dataGridRef;
    dataGrid.updateRows();
  };
}

export function reportsClearFilters() {
  return {
    type: "REPORTS_CLEAR_FILTERS",
  };
}

export function reportsOpenFiltersDialog() {
  return {
    type: "REPORTS_OPEN_FILTERS_DIALOG",
  };
}

export function reportsCloseFiltersDialog() {
  return {
    type: "REPORTS_CLOSE_FILTERS_DIALOG",
  };
}

export function getReports(project_id) {
  return async (dispatch, getState) => {
    const state = getState(),
      client = state.page.elkUser.restClient;
      try {
        dispatch(reportsSetInited(false));
      let reports = await client.get(`service_desc/report/list?project_id=${project_id}`);
      dispatch(reportsSetReports(reports));
    } catch (e) {
      console.log("Ошибка получания отчетов", e);
      return [];
    } finally {
      dispatch(reportsSetInited(true));
    }
  };
}


export function reportsSetDataGridRef(dataGridRef) {
  return {
    type: "REPORTS_SET_DATAGRID_REF",
    dataGridRef,
  };
}

export function reportsExport(path) {
  return async (dispatch, getState) => {
    const state = getState();
    const client = state.page.elkUser.restClient;
    dispatch(reportsSetInited(false));
    try {
      const response = await client.get(`${path}`, {}, {}, false);
      const arr = path.split("/");
      const filename = arr[arr.length - 2];
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const a = document.createElement("a");
      a.href = url;
      a.download = `${filename}.xlsx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (e) {
      console.log("Ошибка экспорта отчетов", e);
    } finally {
      dispatch(reportsSetInited(true));
    }
  };
}
