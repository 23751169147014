export function membersSetInited(inited) {
    return {
        type: 'MEMBERS_SET_INITED',
        inited
    };
}

export function membersSetConfig(membersConfig) {
    return {
        type: 'MEMBERS_SET_MEMBERS',
        membersConfig
    };
}

export function setOpenRoleDialog(isOpenDialog) {
    return {
        type: 'MEMBERS_SET_OPEN_ROLE_DIALOG',
        isOpenDialog
    };
}

export function setMemberDialog(member) {
    return {
        type: 'MEMBERS_SET_MEMBER_DIALOG',
        member
    };
}

export function openMemberRolesDialog(member) {
    return (dispatch) => {
        dispatch(setOpenRoleDialog(true));
        dispatch(setMemberDialog(member));
    }
}

export function membersInit() {
    return async (dispatch, getState) => {
        const state = getState()
        dispatch(membersSetInited(false));
        const membersConfig = await state.page.elkUser.getConfig('users');
        const roleAction = {
            button: {title: "Роли пользователя"},
            code: "user_role_custom",
            is_mass: false,
            onClick: (rowData) => {
                if (rowData.obj_id && rowData.service_event_id) dispatch(openMemberRolesDialog(rowData));
            }
        }
        membersConfig['context_menu'].actions.unshift(roleAction);

        dispatch(membersSetConfig(membersConfig));
        dispatch(membersSetInited(true));
    };
}

