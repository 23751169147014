const initialState = {
    inited: false,
    membersConfig: {},
    isOpenDialog: false,
    member: {}
};

export function members(state = Object.assign({}, initialState), action) {
    switch (action.type) {
        case 'MEMBERS_SET_INITED':
            return Object.assign({}, state, {
                inited: action.inited
            });
        case 'MEMBERS_SET_MEMBERS':
            return Object.assign({}, state, {
                membersConfig: action.membersConfig
            });
        case 'MEMBERS_SET_OPEN_ROLE_DIALOG':
            return Object.assign({}, state, {
                isOpenDialog: action.isOpenDialog
            });
        case 'MEMBERS_SET_MEMBER_DIALOG':
            return Object.assign({}, state, {
                member: action.member
            });
        default:
            return state;
    }
}
