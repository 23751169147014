import {combineReducers} from 'redux';
import {tickets} from "./tickets";
import {ticket} from "./ticket";
import {page} from "./page";
import {reports} from "./reports";
import {ticketScanner} from "./ticketScanner";
import { profile } from './profile';
import {events} from "./events";
import {members} from "./members";
import {roles} from "./roles";
import {directories} from "./directories";
import { lists } from './lists';
import { companies } from './companies';

export default combineReducers({
    tickets,
    ticket,
    page,
    reports,
    ticketScanner,
    profile,
    events,
    members,
    roles,
    directories,
    lists,
    companies
});
