import React from "react";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import { connect } from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import { reportsUpdateCurrentReport, reportsSetDataGridRef, reportsSetReportList } from "../../actions/reports";
import Select from "@ksbteam/core/components/Form/Field/Select";
import DataGrid from "@ksbteam/core/components/DataGrid";
import ExportButton from "../ExportButton/ExportButton";
import { getRows } from "../helpers/helpers";
import Filters from "../Tickets/Filters";

const styles = (theme) => ({
  selectWrapper: {
    marginBottom: theme.spacing(3),
  },
});

class Reports extends React.Component {

  processRows = async (rowsRaw, rows) => {
    const { classes, filters } = this.props;
    let processedRows = rows || [];

    if (!!filters.date.min || !!filters.date.max) {
      processedRows = processedRows.filter((row) => {
        const createdDateStr = row["service_desc-created"];
        if (!createdDateStr) {
          return false; // Если поле отсутствует, строка не проходит фильтрацию
        }

        let date = moment(createdDateStr, "YYYY-MM-DD HH:mm:ss.SSSSSS");
        let matchMin = !!filters.date.min ? date.isSameOrAfter(moment(filters.date.min, "DD.MM.YYYY HH:mm"), "minute") : true;
        let matchMax = !!filters.date.max ? date.isSameOrBefore(moment(filters.date.max, "DD.MM.YYYY HH:mm"), "minute") : true;
        return matchMin && matchMax;
      });
    }

    return processedRows;
  };

  render() {
    let {
      classes,
      inited,
      reports,
      reportList,
      currentReport,
      restClient,
      dataGridRef,
      updateCurrentReport,
      setDataGridRef,
      isAdmin,
      setReportList,
    } = this.props;
    return (
      <React.Fragment>
        {!inited ? (
          <LinearProgress variant="query" />
        ) : (
          <React.Fragment>
            {reports?.length === 0 && <div style={{ fontSize: "larger" }}>Недостаточно прав</div>}
            <div className={classes.selectWrapper}>
              <div style={{ display: "flex", gap: "20px", alignItems: "center", justifyContent: "space-between", marginBottom: "16px" }}>
                <Select
                  label={"Выберите отчет"}
                  id={"report_select"}
                  emptyOptionTitle={"Не выбрано"}
                  options={reports.map((report, index) => {
                    return {
                      value: index,
                      title: report.name,
                    };
                  })}
                  onChange={updateCurrentReport}
                  value={currentReport}
                />
                {isAdmin && reports?.length !== 0 && reportList?.length !== 0 && currentReport !== null && currentReport !== "" && (
                  <ExportButton exportPath={reports[currentReport].export} />
                )}
              </div>
            </div>
            {currentReport !== null && currentReport !== "" && <Filters type={"reports"} />}
            {currentReport !== null && currentReport !== "" && (
              <DataGrid
                key={currentReport}
                toolbar={false}
                restClient={restClient}
                columnsUrl={reports[currentReport]["link_columns"]}
                rowsUrl={reports[currentReport]["link_rows"]}
                getRows={getRows}
                processRows={this.processRows}
                afterLoadRows={(rows) => {
                  setReportList(rows);
                }}
                ref={(dataGrid) => {
                  if (!!dataGrid && !dataGridRef) {
                    setDataGridRef(dataGrid);
                  }
                }}
              />
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    inited: state.reports.inited,
    reports: state.reports.reports,
    reportList: state.reports.reportList,
    currentReport: state.reports.currentReport,
    dataGridRef: state.reports.dataGridRef,
    showDataGrid: state.reports.showDataGrid,
    restClient: state.page.elkUser.restClient,
    isAdmin: state.page.isAdmin,
    filters: state.reports.filters,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCurrentReport: (e) => dispatch(reportsUpdateCurrentReport(e)),
    setDataGridRef: (dataGridRef) => dispatch(reportsSetDataGridRef(dataGridRef)),
    setReportList: (reportList) => dispatch(reportsSetReportList(reportList)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Reports));
