import React from "react";
import { Route, useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoutes = ({ component: Component, ...rest }) => {
  const history = useHistory();
  const location = useLocation();

  const isAdmin = useSelector((state) => state.page.isAdmin);
  const roles = useSelector((state) => state.profile.profileData?.roles || []);
  const isLoaded = useSelector((state) => state.page.isLoaded);

  const [isFirstLoad, setIsFirstLoad] = React.useState(() => {
    const isFirstLogin = localStorage.getItem("isFirstLogin");
    return isFirstLogin === null ? true : JSON.parse(isFirstLogin);
  });

  React.useEffect(() => {
    if (!isLoaded) {
      return;
    }
    if (location.pathname.includes("control") && !isAdmin) {
      history.push("/main");
    }
    if ((location.pathname.includes("lists") || location.pathname.includes("reports")) && roles.some((role) => role === "service_desc_executor")) {
      history.push("/main");
    }
    if (roles.some((role) => role === "service_desc_director") && isFirstLoad) {
      history.push("/reports");
      localStorage.setItem("isFirstLogin", false);
      setIsFirstLoad(false);
    }
    if (!location.pathname.includes("main")) {
      localStorage.removeItem("tab");
    }
    if ((location.pathname.includes("lists") && isAdmin) || (location.pathname.includes("lists") && roles.length === 0)) {
      history.push("/main");
    }
  }, [location, isAdmin, roles, history, isLoaded, isFirstLoad]);

  return (
    <Route
      {...rest}
      render={(props) => <Component {...props} />}
    />
  );
};

export default ProtectedRoutes;
