const initialState = {
  inited: false,
  currentReport: null,
  reports: [],
  reportList: [],
  dataGridRef: null,
  filters: {
    date: {
      min: null,
      max: null,
    },
  },
  filtersDialog: { open: false },
};

export function reports(state = Object.assign({}, initialState), action) {
  switch (action.type) {
    case "REPORTS_SET_INITED":
      return Object.assign({}, state, {
        inited: action.inited,
      });
    case "REPORTS_SET_CURRENT_REPORT":
      return Object.assign({}, state, {
        currentReport: action.currentReport,
      });
    case "REPORTS_SET_REPORTS":
      return Object.assign({}, state, {
        reports: action.reports,
      });
    case "REPORTS_SET_REPORTLIST":
      return Object.assign({}, state, {
        reportList: action.reportList,
      });
    case "REPORTS_SET_DATAGRID_REF":
      return Object.assign({}, state, {
        dataGridRef: action.dataGridRef,
      });
    case "REPORTS_SET_FILTERS":
      return Object.assign({}, state, {
        filters: action.filters,
      });
    case "REPORTS_CLEAR_FILTERS":
      return Object.assign({}, state, {
        filters: {
          date: {
            min: null,
            max: null,
          },
        },
      });
    case "REPORTS_OPEN_FILTERS_DIALOG":
      return Object.assign({}, state, {
        filtersDialog: { open: true },
      });
    case "REPORTS_CLOSE_FILTERS_DIALOG":
      return Object.assign({}, state, {
        filtersDialog: { open: false },
      });
    default:
      return state;
  }
}
