import {useEffect, useState} from "react";
import Field from "@ksbteam/core/components/Form/Field";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useSelector} from "react-redux";

const UserPasswordFormFields = ({fieldProps}) => {
    const [loading, setLoading] = useState(false);
    const user = useSelector((state) => state.page.elkUser);

    useEffect(() => {
        if (fieldProps?.attrs) {
            fieldProps.attrs.disabled = true;
            // console.log('fieldProps', fieldProps);
        }

    }, [])

    const generatePassword = async () => {
        setLoading(true);

        try {
            const password = await user.restClient.get('/service_desc/main/gen-password');
            fieldProps.handleSetFieldValue(fieldProps.attrs.name, password.password);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }

    return <>
        <Field {...fieldProps}/>
        <Button onClick={generatePassword} style={{marginTop: "1rem", backgroundColor: "#3f51b5", color: "#fff"}} disabled={loading}>
            {loading ? <CircularProgress color={'#fff'} size={'1rem'}/> : 'Сгенерировать новый пароль'}
        </Button>
    </>;
}

export default UserPasswordFormFields;