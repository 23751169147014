import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { profileInit } from "../../actions/profile";
import { Typography } from "@material-ui/core";

const styles = () => ({
  profileContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  profileContainerRow: {
    maxWidth: "500px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "20px",
    gap: "16px",
  },
  profileContainerTextField: {
    width: "300px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    border: "none",
    borderBottom: "2px solid rgba(224, 224, 224, 1)",
    background: "none",
    color: "rgba(202, 202, 202, 1.00)",
    paddingBottom: "4px",
  },
  mobileTextField: {
    flexDirection: "column",
  },
});

const Profile = (props) => {
  const { classes, inited, init, profileData, isMobileDevice, projectId } = props;

  const stylesForMobile = (className) => {
    if (isMobileDevice && className === "profileContainerRow") return { flexDirection: "column", alignItems: "start" };
    if (isMobileDevice && className === "profileContainerTextField") return { marginTop: "3px" };
  };

  React.useEffect(() => {
    if (!!projectId) {
      init();
    }
  }, [projectId]);

  return (
    <>
      {!inited && <LinearProgress variant="query" />}
      {inited && profileData.length !== 0 && (
        <div className={classes.profileContainer}>
          <div
            className={classes.profileContainerRow}
            style={stylesForMobile("profileContainerRow")}>
            <span>Полное имя: </span>
            <Typography className={`${classes.profileContainerTextField} ${isMobileDevice ? classes.mobileTextField : ""}`}>
              {profileData.fullName ? profileData.fullName : "---"}
            </Typography>
          </div>
          <div
            className={classes.profileContainerRow}
            style={stylesForMobile("profileContainerRow")}>
            <span>Email: </span>
            <Typography className={`${classes.profileContainerTextField} ${isMobileDevice ? classes.mobileTextField : ""}`}>
              {profileData.email ? profileData.email : "---"}
            </Typography>
          </div>
          <div
            className={classes.profileContainerRow}
            style={stylesForMobile("profileContainerRow")}>
            <span>Телефон: </span>
            <Typography className={`${classes.profileContainerTextField} ${isMobileDevice ? classes.mobileTextField : ""}`}>
              {profileData.phone ? profileData.phone : "---"}
            </Typography>
          </div>
          <div
            className={classes.profileContainerRow}
            style={stylesForMobile("profileContainerRow")}>
            <span>Должность: </span>
            <Typography className={`${classes.profileContainerTextField} ${isMobileDevice ? classes.mobileTextField : ""}`}>
              {!!profileData.roles.length ? profileData.roles.join(", ") : "---"}
            </Typography>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    inited: state.profile.inited,
    profileData: state.profile.profileData,
    isMobileDevice: state.page.isMobileDevice,
    projectId: state.events.currentEvent.obj_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    init: () => dispatch(profileInit()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Profile));
