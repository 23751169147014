import { Box, Button, makeStyles } from "@material-ui/core";
import React from "react";
import ErrorMessage from "../ErrorMessage";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(4),
    margin: `${theme.spacing(2)}px 0`,
  },
  agreeButton: {
    background: "green",
    color: "white",
    "&:hover": {
      background: "green",
    },
  },
}));

function Decide({ IsDircontractor, IsContractor, IsSubcontractor, client, setIsDialogOpen, projectId, ticketId, closeTicket, status }) {
  const [error, setError] = React.useState("");
  const handleOkClick = async () => {
    try {
      if (IsSubcontractor || IsContractor || IsDircontractor) {
        let sendStatus = { status_code: status.code };
        // if (IsDircontractor) {
          sendStatus.status_code = "closed";
        // }
        await client.post(`/service_desc/ticket/define-status/?ticket_id=${ticketId}&project_id=${projectId}`, sendStatus);
      }
      closeTicket();
    } catch (e) {
      console.log("Ошибка запроса", e);
      setError("Ошибка запроса, повторите попытку или попробуйте позже");
    }
  };
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Button
        variant="contained"
        className={classes.agreeButton}
        onClick={handleOkClick}>
        Закрыть
      </Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={setIsDialogOpen}>
        Вернуть на доработку
      </Button>
      <ErrorMessage
        isOpen={!!error}
        onClose={() => setError("")}
        text={error}
      />
    </Box>
  );
}

export default Decide;
