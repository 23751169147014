const initialState = {
    inited: false,
    directoriesConfig: {},
};

export function directories(state = Object.assign({}, initialState), action) {
    switch (action.type) {
        case 'DIRECTORIES_SET_INITED':
            return Object.assign({}, state, {
                inited: action.inited
            });
        case 'DIRECTORIES_SET_DIRECTORIES':
            return Object.assign({}, state, {
                directoriesConfig: action.directoriesConfig
            });
        default:
            return state;
    }
}
