const initialState = {
    inited: false,
    companiesConfig: {},
};

export function companies(state = Object.assign({}, initialState), action) {
    switch (action.type) {
        case 'COMPANIES_SET_INITED':
            return Object.assign({}, state, {
                inited: action.inited
            });
        case 'COMPANIES_SET_COMPANIES':
            return Object.assign({}, state, {
                companiesConfig: action.companiesConfig
            });
        default:
            return state;
    }
}
