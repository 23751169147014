import React from "react";
import { withStyles } from "@material-ui/core/styles";
import DefaultDialog from "@ksbteam/core/components/Dialog/DefaultDialog";
import { ticketsCloseTicket, ticketsMapSetHighLighted, ticketsMapChangeScale, ticketsSetTab } from "../../actions/tickets";
import { ticketSetFormRef, ticketSetFormTempValues, ticketInit, ticketSetEditDisabled } from "../../actions/ticket";
import { connect } from "react-redux";
import SimpleForm from "@ksbteam/core/components/Form/SimpleForm";
import Comments from "./Comments";
import Typography from "@material-ui/core/Typography";
import CommentForm from "./Comments/CommentForm";
import CircularProgress from "@material-ui/core/CircularProgress";
import TicketButtons from "./TicketButtons";
import MapIcon from "@material-ui/icons/Map";
import History from "./History";
import Button from "@material-ui/core/Button";
import Assign from "./Assign";

const styles = (theme) => ({
  progressWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  editFormWrapper: {
    marginBottom: theme.spacing(2),
    "&.disabled .MuiFormLabel-root.Mui-disabled": {
      color: "#5a5a5a",
    },
    "&.disabled .MuiInputBase-root.Mui-disabled": {
      color: "#5a5a5a",
      cursor: "not-allowed",
    },
  },
  showMapButton: {
    textTransform: "none",
    padding: 0,
  },
});

class Ticket extends React.Component {
  componentDidMount() {
    this.props.init();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { formRef, setFormTempValues, formTempValues } = this.props;
    if (!!formRef && !formTempValues) {
      setFormTempValues(formRef.getPreparedValues());
    }
  }
  handleButtonDisable = () => {
    const isValid = this.props.formRef?.values["service_desc-incident_level"];
    if (isValid !== undefined && !isValid !== this.props.editDisabled) {
      this.props.setDisabled(!isValid);
    }
    if (
      this.props.formRef?.values["service_desc-incident_level"] !== this.props.formConfig.values["service_desc-incident_level"] &&
      !this.props.editDisabled
    ) {
    }
  };

  showMapClick = () => {
    const { closeTicket, mapSetHighLighted, mapChangeScale, ticketId, setTab } = this.props;
    mapSetHighLighted([ticketId]);
    mapChangeScale(0.65);
    setTab("map");
    closeTicket();
  };

  render() {
    const {
      classes,
      open,
      ticketId,
      status,
      inited,
      editMode,
      formConfig,
      formRef,
      restClient,
      closeTicket,
      setFormRef,
      roles,
      isResponsinble,
      projectId,
      isAdmin,
      editDisabled,
    } = this.props;
    const handleChange = () => {
      console.log("hande");
    };
    const statusText = !!status && status.title ? `(${status.title})` : "";
    const titleDefaultText = `Инцидент ${ticketId} ${statusText}`;
    const hasRoles = !!roles.length;
    const IsDirector = roles.some((role) => role === "service_desc_director");
    const IsDircontractor = roles.some((role) => role === "service_desc_manager_company");
    const IsContractor = roles.some((role) => role === "service_desc_contractor");
    const IsSubcontractor = roles.some((role) => role === "service_desc_subcontractor");
    const isExecutor = roles.some((role) => role === "service_desc_executor");
    const IsContractorOrSubcontractorAndStatus =
      (IsContractor || IsSubcontractor) && (status?.value === "1569911" || status?.value === "1569912" || status?.value === "1569999");
    const isDircontractorAndStatus = IsDircontractor && (status?.value === "1569911" || status?.value === "1569912" || status?.value === "1569999");
    const isDircontractorAndNewStatus = IsDircontractor && status?.value === "1569910";
    const canPerformAction =
      ((hasRoles && !isExecutor && (IsContractorOrSubcontractorAndStatus || isDircontractorAndStatus) && isResponsinble) ||
        isDircontractorAndNewStatus ||
        (IsDirector && (status?.value === "1569912" || status?.value === "1569999")) ||
        isAdmin) &&
      status?.value !== "1569913";

    let title = (
      <React.Fragment>
        {titleDefaultText}
        <div>
          <Button
            className={classes.showMapButton}
            onClick={this.showMapClick}>
            <MapIcon /> {"Показать на схеме"}
          </Button>
        </div>
      </React.Fragment>
    );
    return (
      <DefaultDialog
        open={open}
        fullScreen={true}
        onClose={closeTicket}
        title={title}>
        {!inited && (
          <div className={classes.progressWrapper}>
            <CircularProgress size={40} />
          </div>
        )}
        {inited && (
          <React.Fragment>
            <div className={classes.editFormWrapper + (!editDisabled ? " disabled" : "")}>
              <TicketButtons
                hasRoles={hasRoles}
                IsDirector={IsDirector}
                IsDircontractor={IsDircontractor}
                IsContractor={IsContractor}
                IsSubcontractor={IsSubcontractor}
                isExecutor={isExecutor}
              />
              <SimpleForm
                ref={(simpleForm) => {
                  if (!formRef && !!simpleForm) {
                    setFormRef(simpleForm);
                  }
                }}
                {...formConfig}
                inited={true}
                restClient={restClient}
                hideSubmitButton={true}
                initValues={formConfig.values}
                onChange={handleChange}
                onValidate={this.handleButtonDisable}
              />
            </div>
            {canPerformAction && (
              <Assign
                restClient={this.props.restClient}
                tiketID={this.props.ticketId}
                closeTicket={closeTicket}
                projectId={projectId}
                isAdmin={isAdmin}
                IsDirector={IsDirector}
                IsDircontractor={IsDircontractor}
                IsContractor={IsContractor}
                IsSubcontractor={IsSubcontractor}
                status={status}
                editDisabled={editDisabled}
              />
            )}
            {!editMode && (
              <React.Fragment>
                <Typography
                  variant="h2"
                  component="h2">
                  {"Комментарии"}
                </Typography>
                <Comments />
                <CommentForm />
                <Typography
                  variant="h2"
                  component="h2">
                  {"История изменений"}
                </Typography>
                <History />
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </DefaultDialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    open: state.tickets.ticketIsOpen,
    ticketId: state.tickets.ticketId,
    inited: state.ticket.inited,
    formConfig: state.ticket.formConfig,
    formRef: state.ticket.formRef,
    formTempValues: state.ticket.formTempValues,
    restClient: state.page.elkUser.restClient,
    editMode: state.ticket.editMode,
    status: state.ticket.status,
    editDisabled: state.ticket.editDisabled,
    roles: state.profile.profileData?.roles || [],
    isResponsinble: state.ticket.isResponsinble,
    isAdmin: state.page.isAdmin,
    projectId: state.events.currentEvent.obj_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeTicket: () => dispatch(ticketsCloseTicket()),
    init: () => dispatch(ticketInit()),
    setFormRef: (formRef) => dispatch(ticketSetFormRef(formRef)),
    mapSetHighLighted: (highLighted) => dispatch(ticketsMapSetHighLighted(highLighted)),
    mapChangeScale: (scale) => dispatch(ticketsMapChangeScale(scale)),
    setTab: (tab) => dispatch(ticketsSetTab(tab)),
    setFormTempValues: (values) => dispatch(ticketSetFormTempValues(values)),
    setDisabled: (bool) => dispatch(ticketSetEditDisabled(bool)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Ticket));
