export function eventsSetInited(inited) {
  return {
    type: "EVENTS_SET_INITED",
    inited,
  };
}

export function eventsSetConfig(eventsConfig) {
  return {
    type: "EVENTS_SET_EVENTS",
    eventsConfig,
  };
}

export function setCurrentEvent(currentEvent) {
  return {
    type: "SET_CURRENT_EVENT",
    currentEvent,
  };
}
export function setEventList(eventList) {
  return {
    type: "SET_EVENT_LIST",
    eventList,
  };
}

export function getEvents() {
  return async (dispatch, getState) => {
    let events = [];
    try {
      const state = getState();
      const client = state.page.elkUser.restClient;
      events = await client.get("service_desc/event/list");
      if (!!events.length) {
        dispatch(setEventList(events));
        dispatch(setCurrentEvent(events[0]));
      }
    } catch (error) {
      console.error("Ошибка при получении списка событий:", error);
    }
    return events;
  };
}

export function eventsInit() {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(eventsSetInited(false));
    try {
      let eventsConfig = await state.page.elkUser.getConfig("events");
      dispatch(eventsSetConfig(eventsConfig));
    } catch (error) {
      console.error("Ошибка при инициализации событий:", error);
    } finally {
      dispatch(eventsSetInited(true));
    }
  };
}
