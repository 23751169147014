export function companiesSetInited(inited) {
  return {
    type: "COMPANIES_SET_INITED",
    inited,
  };
}

export function companiesSetConfig(companiesConfig) {
  return {
    type: "COMPANIES_SET_COMPANIES",
    companiesConfig,
  };
}

export function getCompaniesConfig() {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const client = state.page.elkUser.restClient;
      const companiesConfig = await client.get("/service_desc/org/org-config/");
      if (!!Object.keys(companiesConfig).length) {
        dispatch(companiesSetConfig(companiesConfig));
      }
    } catch (error) {
      console.error("Ошибка при получении списка событий:", error);
    }
  };
}

export function companiesInit() {
  return async (dispatch, getState) => {
    dispatch(companiesSetInited(false));
    try {
      dispatch(getCompaniesConfig());
    } catch (error) {
      console.error("Ошибка при инициализации событий:", error);
    } finally {
      dispatch(companiesSetInited(true));
    }
  };
}
