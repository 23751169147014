import { setAdminIsLoaded } from "./page";

export function profileSetInited(inited) {
  return {
    type: "PROFILE_SET_INITED",
    inited,
  };
}

export function setProfileData(profileData) {
  return {
    type: "SET_PROFILE_DATA",
    profileData,
  };
}

export function profileInit() {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const client = state.page.elkUser.restClient;
      const projectId = state.events.currentEvent.obj_id;
      dispatch(profileSetInited(false));
      if (!!client && !!projectId) {
        const profileData = await client.get(`service_desc/members/info?project_id=${projectId}`);
        dispatch(setProfileData(profileData));
        dispatch(profileSetInited(true));
      }
    } catch (e) {
      console.log("Ошибка при загрузке данных профиля,", e);
    } finally {
      dispatch(setAdminIsLoaded(true));
    }
  };
}
