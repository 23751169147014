import { Alert } from "@material-ui/lab";
import { Snackbar } from "@material-ui/core";
import React from "react";

function ErrorMessage({isOpen, onClose, text}) {
  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={6000}
      onClose={onClose}>
      <Alert
        severity="error"
        elevation={6}
        variant="filled">
        {text}
      </Alert>
    </Snackbar>
  );
}

export default ErrorMessage;
