const initialState = {
    inited: false,
    rolesConfig: {},
};

export function roles(state = Object.assign({}, initialState), action) {
    switch (action.type) {
        case 'ROLES_SET_INITED':
            return Object.assign({}, state, {
                inited: action.inited
            });
        case 'ROLES_SET_ROLES':
            return Object.assign({}, state, {
                rolesConfig: action.rolesConfig
            });
        default:
            return state;
    }
}
