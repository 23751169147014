import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {connect} from "react-redux";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    card: {
        minWidth: 275,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    author: {
        fontSize: 16,
        marginBottom: theme.spacing()
    },
    pos: {
        marginBottom: 12,
    },
});

class Comments extends React.Component {
    render() {
        let {classes, comments} = this.props;
        return (
            <React.Fragment>
                {comments.reverse().map(comment =>
                    <Card
                        id={`ticket-comment-${comment['obj_id']}`}
                        className={classes.card + ' ticket-comment'}
                        key={comment['obj_id']}
                    >
                        <CardContent>
                            <Typography className={classes.date} color="textSecondary" gutterBottom>
                                {comment.created}
                            </Typography>
                            <Typography className={classes.author} variant="h5" component="h5">
                                {comment.author}
                            </Typography>
                            <Typography variant="body1" component="p">
                                {comment.comment}
                            </Typography>
                        </CardContent>
                    </Card>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        comments: state.ticket.comments
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Comments));
