import { Checkbox, Dialog, DialogContent, DialogTitle, FormControl, Snackbar } from "@material-ui/core";
import { useEffect, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import { Alert } from "@material-ui/lab";

const UserRoleDialog = ({ isOpen, onClose, member, client }) => {
  const [roleList, setRoleList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    if (isOpen && member && !!Object.keys(member)) {
      loadRoles();
    }
    return () => {
      setRoleList([]);
    };
  }, [isOpen, member]);

  const loadRoles = async () => {
    setLoading(true);
    try {
      const roles = await client.post(`/service_desc/users/get-role-fields?project_id=${member.service_event_id}`, { obj_id: member.obj_id });
      const initValue = {};
      roles.forEach((item) => {
        initValue[item.attrs.name] = item.attrs.value;
      });
      setRoleList(roles);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoadingSubmit(true);
    const formValues = {};
    formValues["obj_id"] = member.obj_id;
    roleList.forEach((item) => {
      const checkbox = e.target.querySelector(`[name="${item.attrs.name}"] input[type="checkbox"]`);
      const isChecked = checkbox.checked;
      formValues[item.attrs.name] = Number(isChecked);
    });

    try {
      await client.post(`/service_desc/users/set-roles?project_id=${member.service_event_id}`, formValues);
      setSnackbarOpen(true);
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingSubmit(false);
    }
  };
  const handleClose = () => {
    setRoleList([]);
    setSnackbarOpen(false);
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      open={isOpen}
      fullWidth={true}>
      <DialogTitle>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>Роли пользователя</div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => handleClose()}>
            &times;
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <div style={{ padding: "1.5rem 0" }}>
          {loading && <CircularProgress style={{ display: "block", margin: "0 auto" }} />}
          {!loading && roleList.length > 0 && (
            <form
              id={"role_users"}
              name="role_users"
              onSubmit={submitHandler}>
              {roleList.map((item, index) => (
                <div key={item.label + index}>
                  <FormControl
                    defaultValue={item.attrs.value ? 1 : 0}
                    name={item.attrs.name}
                    labelPosition="start"
                    style={{ flexDirection: "row", alignItems: "center" }}
                    onSubmit={submitHandler}>
                    <Checkbox
                      color="primary"
                      defaultChecked={item.attrs.value}
                    />
                    <span>{item.label}</span>
                  </FormControl>
                </div>
              ))}
              <Snackbar
                autoHideDuration={5000}
                onClose={() => setSnackbarOpen(false)}
                open={snackbarOpen}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                <Alert severity={"success"}>Данные успешно сохранены</Alert>
              </Snackbar>
              {loadingSubmit ? (
                <CircularProgress size={0.5} />
              ) : (
                <Button
                  fullWidth={true}
                  type={"submit"}
                  style={{ color: "#fff", backgroundColor: "rgb(86, 130, 163)", marginTop: "1rem" }}>
                  Сохранить
                </Button>
              )}
            </form>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default UserRoleDialog;
