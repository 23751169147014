import { Box, Button, Dialog, DialogContent, DialogTitle, TextField, makeStyles } from "@material-ui/core";
import React, { useState } from "react";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  buttons: {
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "center",
    justifyContent: "center",
  },
  agreeButton: {
    background: "green",
    color: "white",
    "&:hover": {
      background: "green",
    },
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      border: '1px solid #000',
      borderRadius: theme.shape.borderRadius,
    },
    "& .MuiOutlinedInput-multiline": {
      padding: 0,
    },
    "& .MuiOutlinedInput-inputMultiline": {
      padding: theme.spacing(1),
      minHeight: '100px',
    },
  },
}));

function ReasonDialog({ isDialogOpen, setIsDialogOpen, handleSubmit }) {
  const classes = useStyles();
  const [reason, setReason] = useState("");

  const handleTextChange = (event) => {
    setReason(event.target.value);
  };

  const handleAgreeClick = async() => {
    await handleSubmit(reason, true);
    setIsDialogOpen(false)
  };

  return (
    <Dialog
      open={isDialogOpen}
      onClose={() => setIsDialogOpen(false)}>
      <DialogTitle>Пожалуйста, напишите отчет о решении данного инцидента</DialogTitle>
      <DialogContent>
        <form className={classes.form}>
          <TextField
            multiline={true}
            rows={3}
            value={reason}
            placeholder='Текст отчета'
            onChange={handleTextChange}
            variant="outlined"
            className={classes.textField}
          />
          <Box className={classes.buttons}>
            <Button
              variant="contained"
              className={classes.agreeButton}
              onClick={handleAgreeClick}
              disabled={!reason.trim() || reason.length < 3}
            >
              Отправить
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setIsDialogOpen(false)}
            >
              Закрыть
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default ReasonDialog;
