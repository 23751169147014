const initialState = {
    inited: false,
    eventsConfig: {},
    currentEvent: {},
    eventList: []
};

export function events(state = Object.assign({}, initialState), action) {
    switch (action.type) {
        case 'EVENTS_SET_INITED':
            return Object.assign({}, state, {
                inited: action.inited
            });
        case 'EVENTS_SET_EVENTS':
            return Object.assign({}, state, {
                eventsConfig: action.eventsConfig
            });
        case 'SET_CURRENT_EVENT':
            return Object.assign({}, state, {
                currentEvent: action.currentEvent
            });
        case 'SET_EVENT_LIST':
            return Object.assign({}, state, {
                eventList: action.eventList
            });
        default:
            return state;
    }
}
