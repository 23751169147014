import Cookies from 'universal-cookie';
import RestClient from '@ksbteam/core/api/RestClient';

const cookies = new Cookies();
export default class ElkUser {
    constructor(dispatch) {
        this.dispatch = dispatch;
        this._authToken = cookies.get('authToken');
        this._twoStepAuth = cookies.get('twoStepAuth');
        this.initRestClient();
    }

    initRestClient() {
        if (!!this.authToken && !!this.authToken.length) {
            this.restClient = new RestClient({
                authToken: this.authToken,
                user: this,
                logoutUnauthorized: true,
                host: import.meta.env.VITE_REST_SERVICE,
                headers: { "Project": "service_desc"}
            });
        }
    }

    async getAdminStatus() {
        if (!!this.authToken && !!this.authToken.length && !!this.restClient) {
            try {
                const isAdmin = await this.restClient.get(`/service_desc/members/get-status-user`);
                if (isAdmin.is_admin) {
                    this.isAdmin = isAdmin.is_admin
                }
                else this.isAdmin = false
            }
            catch(e) {
                console.log(e)
                this.isAdmin = false
            }
        }
        return this.isAdmin
    }

    async getConfig(type) {
        if (!!this.authToken && !!this.authToken.length && !!this.restClient) {
            try {
                this.config = await this.restClient.get(`/service_desc/grids/config?obj_type_code=acs_${type}`);
            }
            catch(e) {
                console.log(e)
                this.config = {}
            }
        }
        return this.config
    }

    get reportsCount() {
        return this._reportsCount;
    }

    set reportsCount(value) {
        this._reportsCount = value;
        cookies.set('reportsCount', value, {path: '/'});
    }

    get authToken() {
        return this._authToken;
    }

    set authToken(value) {
        this._authToken = value;
        cookies.set('authToken', value, {path: '/'});
    }

    get twoStepAuth() {
        return this._twoStepAuth;
    }

    set twoStepAuth(value) {
        this._twoStepAuth = value;
        cookies.set('twoStepAuth', value, {path: '/'});
    }

    loggedIn() {
        return !!this.authToken && this.authToken.length;
    }

    passedTwoStepAuth() {
        return true;
        // return !!this.twoStepAuth && +this.twoStepAuth === 1;
    }

    async login(login, password) {
        const tempClient = new RestClient({
            authType: 'Basic',
            authToken: btoa(login + ':' + password),
            logoutUnauthorized: false,
            host: import.meta.env.VITE_REST_SERVICE
        });
        localStorage.setItem('isFirstLogin', true)
        this.authToken = await tempClient.get('service_desc/login/token?fingerprint=123');
        this.initRestClient();
    }

    async passTwoStepAuth() {
        this.twoStepAuth = 1;
    }

    async logout() {
        this.authToken = '';
        this.twoStepAuth = 0;
        this.reportsCount = 0;
        this.goLoginPage();
        localStorage.removeItem('isFirstLogin')
    }

    goLoginPage() {
        if (!this.authToken.length && window.location.href.indexOf(`/login`) === -1) {
            window.location.href = `/login`;
        }
    }

    goMainPage() {
        if (this.authToken.length) {
            window.location.href = `/main`;
        }
    }

    goAuth2Step() {
        this.logout();
    }
}
