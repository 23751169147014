const initialState = {
  optionList: [],
  avaibleList: [],
  currentList: {},
  successMessage: "",
  errorMessage: ""
};

export function lists(state = Object.assign({}, initialState), action) {
  switch (action.type) {
    case "SET_AVAIBLELISTS":
      return Object.assign({}, state, {
        avaibleList: action.avaibleList,
      });
    case "SET_CURRENTLIST":
      return Object.assign({}, state, {
        currentList: action.currentList,
      });
      case "SET_SUCCESS_MESSAGE":
      return Object.assign({}, state, {
        successMessage: action.successMessage,
      });
      case "SET_ERROR_MESSAGE":
      return Object.assign({}, state, {
        errorMessage: action.errorMessage,
      });
    default:
      return state;
  }
}
