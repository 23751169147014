import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { withStyles } from "@material-ui/core/styles";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MoreIcon from "@material-ui/icons/MoreVert";
import ElkUser from "../../api/ElkUser";
import NavBar from "./NavBar";
import MenuIcon from "@material-ui/icons/Menu";
import { pageToggleDrawerLeft } from "../../actions/page";
import { connect } from "react-redux";
import { t, language } from "@ksbteam/core/api/DummyTranslate";
import { Link, NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { Select } from "@material-ui/core";
import { setCurrentEvent } from "../../actions/events";

const styles = (theme) => ({
  grow: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    height: "56px",
    boxShadow: "0 4px 8px -3px rgba(17, 17, 17, .04)",
    backgroundColor: "#ffffff",
    left: 0,
  },
  toolbar: {
    height: "56px",
    minHeight: "auto",
  },
  drawerMenuButton: {
    marginLeft: -12,
    marginRight: 10,
    [`@media (min-width: 1150px)`]: {
      marginRight: 20,
      paddingRight: 12,
      display: "none",
    },
  },
  title: {
    display: "block",
    marginRight: "0.5em",
    [`@media (min-width: 650px)`]: {
        marginRight: "2em",
      },
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    paddingTop: theme.spacing(),
    paddingRight: theme.spacing(),
    paddingBottom: theme.spacing(),
    paddingLeft: theme.spacing(10),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
  sectionDesktop: {
    gap: "5px",
    display: "flex",
    flexWrap: "nowrap",
    [`@media (min-width: 650px)`]: {
      gap: "16px",
      alignItems: "center",
    },
  },
  sectionMobile: {
    display: "flex",
    alignItems: "center",
    [`@media (min-width: 650px)`]: {
      display: "none",
    },
  },
  navLink: {
    display: "block",
    backgroundColor: "transparent",
    color: theme.palette.text.primary,
    textDecoration: "none",
    "&.active": {
      color: theme.palette.secondary.light,
    },
    "&.active *": {
      color: theme.palette.secondary.light,
    },
  },
  profileNavLink: {
    backgroundColor: "transparent",
    color: theme.palette.text.primary,
    textDecoration: "none",
    "&.active": {
      color: theme.palette.secondary.light,
    },
    "&.active *": {
      color: theme.palette.secondary.light,
    },
    "&:focus": {
      outline: "none",
      border: "none",
    },
  },
  iconsMenu: {
    display: "flex",
    alignItems: "center",
    width: "115px",
    justifyContent: "space-between",
    marginRight: "50px",
    "& svg": {
      color: "#606060",
      fontSize: 23,
    },
  },
  selectMobile: {
    fontSize: '12px'
  }
});

class AppBarTop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      mobileMoreAnchorEl: null,
    };
    this.elkUser = new ElkUser();
  }

  handleProfileMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleMobileMenuOpen = (event) => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  render() {
    const { anchorEl, mobileMoreAnchorEl } = this.state;
    const { classes, isMobileDevice, handleCurrentEvent, currentEvent, events, isAdmin } = this.props;
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const currentUrl = window.location.href;

    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}>
        <MenuItem onClick={() => this.elkUser.logout()}>{t("Log out")}</MenuItem>
        {isAdmin && (
          <MenuItem>
            <NavLink
              to={"/control/events"}
              className={classes.navLink}>
              Администрирование
            </NavLink>
          </MenuItem>
        )}
      </Menu>
    );

    const renderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMobileMenuOpen}
        onClose={this.handleMenuClose}>
        <MenuItem onClick={() => this.elkUser.logout()}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <Typography variant="body1">{t("Log out")}</Typography>
        </MenuItem>
        <MenuItem>
          {isAdmin && (
            <NavLink
              to={"control/events"}
              className={classes.navLink}>
              <Typography variant="body1">Администрирование</Typography>
            </NavLink>
          )}
        </MenuItem>
      </Menu>
    );

    return (
      <div className={classes.root}>
        <AppBar
          position="fixed"
          className={classes.appBar}
          color="default">
          <Toolbar className={classes.toolbar}>
            <IconButton
              className={classes.drawerMenuButton}
              color="inherit"
              aria-label="Open drawer"
              onClick={this.props.toggleDrawerLeft}>
              <MenuIcon />
            </IconButton>
            <Typography
              className={classes.title}
              variant="h6"
              color="inherit"
              noWrap>
              <Link to="/main">
                <img
                  style={{ width: 175, maxWidth: "100%", display: "block" }}
                  src={
                    language === "ru"
                      ? "https://roscongress.org/bitrix/templates/rc2/img/logo.svg"
                      : "https://roscongress.org/bitrix/templates/rc2/img/logo_en.svg"
                  }
                  alt=""
                />
              </Link>
            </Typography>
            <NavBar />
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              {!currentUrl.includes("control") && (
                <Select
                  label={"Мероприятие"}
                  id={"events_select"}
                  disabled={true}
                  className={`${isMobileDevice ? classes.selectMobile : ''}`}
                  value={currentEvent?.obj_id || ""}
                  onChange={(event) => handleCurrentEvent(events.find((item) => item.obj_id === event.target.value))}>
                  {events.map((event) => (
                    <MenuItem
                      key={event.obj_id}
                      value={event.obj_id}>
                      {event.short_name}
                    </MenuItem>
                  ))}
                </Select>
              )}
              <IconButton
                aria-owns={isMenuOpen ? "material-appbar" : undefined}
                aria-haspopup="true"
                onClick={this.handleProfileMenuOpen}
                color="inherit">
                <AccountCircle />
              </IconButton>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-haspopup="true"
                onClick={this.handleMobileMenuOpen}
                color="inherit">
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {renderMenu}
        {renderMobileMenu}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    drawerLeft: state.page.drawerLeft,
    currentEvent: state.events.currentEvent,
    events: state.events.eventList,
    isAdmin: state.page.isAdmin,
    isMobileDevice: state.page.isMobileDevice,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleDrawerLeft: () => dispatch(pageToggleDrawerLeft()),
    handleCurrentEvent: (event) => dispatch(setCurrentEvent(event)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AppBarTop));
