export const DATAGRID_DEFAULT_PROPS = {
    disableInterfaceStorage: true,
    toolbarProps: {
        settingsEnabled: false
    },
    columns: [
        {
            "key": "mobile_card",
            "name": "Инциденты",
            "sortable": false,
            "width": document.documentElement.clientWidth - 46
        },
        {
            "key": "service_desc-created",
            "type": "datetime",
        },
        {
            "key": "service_desc-sort_id",
            visible:false
        },
        {
            "key": "service_desc-obj_id",
        },
        {
            "key": "service_desc-subject",
        },
        {
            "key": "service_desc-incident_type",
        },
        {
            "key": "service_desc-incident_level",
        },
        {
            "key": "service_desc-location",
        },
        {
            "key": "service_desc-description",
        },
        {
            "key": "service_desc-status",
        },
        {
            "key": "service_desc-is_danger",
        },
        {
            "key": "service_desc-assigned",
        },
        {
            "key": "service_desc-responsible_ref",
        },
        {
            "key": "service_desc-dircontractor",
        },
        {
            "key": "service_desc-contractor",
        },
        {
            "key": "service_desc-subcontractor",
        },
        {
            "key": "service_desc-performer",
        },
        {
            "key": "service_desc-status_code",
            "visible": false
        }
    ]
};

export const TICKET_COLORS = {
    new: 'rgb(192, 0, 0)',
    completed: 'rgb(0, 176, 80)',
    assigned: 'rgb(255, 0, 0)',
    closed: 'rgb(175, 171, 171)',
    work: 'rgb(0, 112, 192)',
    not_completed: 'rgb(13, 13, 13)',
    rework: 'rgb(0, 176, 240)',
    rejected: 'rgb(112, 48, 160)'
};
