import React from 'react';
import AppBarTop from '../components/AppBarTop';
import Typography from '@material-ui/core/Typography';
import {Redirect} from 'react-router';
import DrawerLeft from '../components/DrawerLeft';
import {connect} from "react-redux";
import {pageInitFirebase} from "../actions/page";

class ElkPage extends React.Component {
    componentDidMount() {
        // this.props.initFirebase();
    }

    render() {
        const {elkUser, isMobileDevice, noPadding} = this.props;
        if (!elkUser.loggedIn()) {
            window.location.href = `/login`;
            return <Redirect push to={'/login'}/>;
        }
        if (!elkUser.passedTwoStepAuth()) {
            return <Redirect push to={`/two-step-auth`}/>;
        }
        let wrapperInnerStyle = {
            margin: '56px auto 0',
            position: 'relative'
        };
        let contentStyle = {padding: noPadding ? 0 : (isMobileDevice ? 15 : 24)};
        return (
            <div>
                <AppBarTop elkUser={elkUser}/>
                <DrawerLeft/>
                <div>
                    <div style={wrapperInnerStyle}>
                        <main style={contentStyle}>
                            {!!this.props.title &&
                            <Typography variant="h1" noWrap
                                        style={{marginBottom: '1.5em', marginTop: '1em', whiteSpace: 'normal'}}>
                                {this.props.title}
                            </Typography>}
                            {this.props.children}
                        </main>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        elkUser: state.page.elkUser,
        isMobileDevice: state.page.isMobileDevice
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        initFirebase: () => dispatch(pageInitFirebase())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ElkPage);

